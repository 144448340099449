import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Link } from "gatsby"

export default () => (
  <Layout>
    <SEO
      title="Lead Golang Developer - Careers at D2i Technology"
      description="Join our team as a Lead Golang Developer. 8+ years of experience required, including 4+ years in Golang and expertise in GoRoutines and multi-cloud platforms (AWS, GCP, Azure)."
    />
    <section id="career-detail" className="career-detail">
      <div className="container" data-aos-todo="fade-up">
        <div className="section-title">
          <h1>Lead Golang Developer</h1>
        </div>
        <div className="row mt-5">
          <div className="col-lg-8 mx-auto">
            <nav aria-label="breadcrumb">
              <ol className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/careers">Careers</Link>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Lead Golang Developer
                </li>
              </ol>
            </nav>
            <div className="job-card">
              <div className="job-header">
                <h2>Job Description</h2>
                <Link
                  to="https://forms.gle/mCYwx55YhT6vA1cJ7"
                  target="_blank"
                  className="btn btn-primary"
                >
                  Apply Now
                </Link>
              </div>
              <p>
                We are looking for an experienced Lead Golang Developer to join
                our innovative team. The ideal candidate will have a strong
                background in Golang development and expertise in multi-cloud
                platforms.
              </p>

              <h3>Requirements:</h3>
              <ul>
                <li>8+ years of overall software development experience</li>
                <li>4+ years of hands-on experience with Golang</li>
                <li>Expertise in GoRoutines and concurrent programming</li>
                <li>Proficiency in multi-cloud platforms (AWS, GCP, Azure)</li>
                <li>
                  Strong understanding of distributed systems and microservices
                  architecture
                </li>
                <li>
                  Experience with containerization technologies (e.g., Docker,
                  Kubernetes)
                </li>
                <li>Excellent problem-solving and communication skills</li>
              </ul>

              <h3>Responsibilities:</h3>
              <ul>
                <li>
                  Lead the design and development of scalable, high-performance
                  Golang applications
                </li>
                <li>
                  Mentor and guide junior developers in best practices and
                  coding standards
                </li>
                <li>
                  Collaborate with cross-functional teams to define and
                  implement software solutions
                </li>
                <li>
                  Optimize application performance and ensure code quality
                  through rigorous testing and code reviews
                </li>
                <li>
                  Contribute to architectural decisions and technology choices
                </li>
                <li>
                  Stay up-to-date with the latest trends and advancements in
                  Golang and cloud technologies
                </li>
              </ul>

              <h3>What We Offer:</h3>
              <ul>
                <li>Competitive salary commensurate with experience</li>
                <li>
                  Opportunity to work on cutting-edge projects using the latest
                  technologies
                </li>
                <li>Professional development and learning opportunities</li>
                <li>Flexible work environment and work-life balance</li>
                <li>Collaborative and innovative team culture</li>
              </ul>
            </div>
            <div className="apply-footer">
              <p>Ready to lead our Golang development efforts?</p>
              <Link
                to="https://forms.gle/mCYwx55YhT6vA1cJ7"
                target="_blank"
                className="btn btn-primary"
              >
                Apply for this Position
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)
